.gameScore {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.gameScore__centredContainer {
  height: auto;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.scoreMarker {
  position: relative;
  max-width: 200px;
  /* flex: 1 1 0; width: 0; forces all score markers to have the same width */
  flex: 1 1 0;
  width: 0;
}

.scoreMarker__hexagon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scoreMarker__centerHexagon {
  max-width: 100px;
  min-width: 64px;
}

.score_marker--animateIn {
  animation-name: scoreMarkerIn;
  animation-delay: 0.5s;
  animation-duration: 1s;
}

@keyframes scoreMarkerIn {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}

.score_marker--uploaderAnimateIn {
  animation-name: scoreMarkerIn;
  animation-duration: 1s;
  animation-delay: 1s;
}

.score_marker--animateShake {
  animation-name: scoreMarkerShake;
  animation-duration: 0.1s;
  animation-iteration-count: 10;
}

@keyframes scoreMarkerShake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5%);
  }
  75% {
    transform: translateX(-5%);
  }
  100% {
    transform: translateX(0);
  }
}

.score_marker--animateIncrementScore {
  animation-name: scoreMarkerIncrementScore;
  animation-duration: 1s;
  transform: translateX(100%);
}

@keyframes scoreMarkerIncrementScore {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.score_marker--animateFinalScore {
  animation-name: scoreMarkerFinalScore;
  animation-duration: 1s;
  transform: translateX(100%);
  z-index: 1;
}

@keyframes scoreMarkerFinalScore {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.score_marker--animateFinalScorePulse {
  animation-name: scoreMarkerFinalScorePulse;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  transform: translateX(100%);
  z-index: 1;
}

@keyframes scoreMarkerFinalScorePulse {
  from {
    transform: translateX(100%) scale(1);
  }
  50% {
    transform: translateX(100%) scale(1.1);
  }
  to {
    transform: translateX(100%) scale(1);
  }
}

.score_marker--animateFinalScoreUploader {
  animation-name: pulse;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-delay: 1s;
}

@keyframes pulse {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  to {
    transform: scale(1);
  }
}

.scoreMarker__trackItem {
  position: relative;
  margin-top: 10px;
}

.scoreMarker__trackItemLineContainer__start, .scoreMarker__trackItemLineContainer__main, .scoreMarker__trackItemLineContainer__end {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -10;
}

.scoreMarker__trackItemLineContainer__start {
  left: 50%;
}

.scoreMarker__trackItemLineContainer__end {
  right: 50%;
}

.scoreMarker__trackItemLine {
  height: 1px;
  width: 100%;
  background-color: #979797;
}

.scoreMarker__trackItemDotContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scoreMarker__trackItemDot {
  position: relative;
  width: 20%;
  max-width: 25px;
  min-width: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
