.landscapeView {
  position: relative;
  width: 100%;
  height: 100%;
}

.landscapeError {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  background-color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.landscapeError__inner {
  max-width: 300px;
}

.landscapeError__message {
  color: white;
}

.landscapeError__image {
  margin-top: 30px;
}
