.GameAnswerResultScreen__background {
  background-image: linear-gradient(90deg, #30B8FD , #1878E9);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.GameAnswerResultScreen__mainIconContainer {
  height: 50%;
  width: 60%;
  max-width: 1000px;
  max-height: 500px;
  display: flex;
  flex-direction: row;
}

.GameAnswerResultScreen__mainIconContainer > * {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50%;
  border-width: 1.7vw;
  border-style: solid;
  border-color: white;
  max-width: 1000px;
  max-height: 500px;
}

.mainIconContainer__purpleBox{
  border-top-left-radius: 8vw;
  border-bottom-left-radius: 8vw;
  border-right: none;
}

.mainIconContainer__whiteBox {
  border-top-right-radius: 8vw;
  border-bottom-right-radius: 8vw;
  background-color:white;
  border-left: none;
}

.mainIconContainer__icon {
  width: 15vw;
  max-width: 170px;
}

.replayVideoButton {
  position: absolute;
  left: 5%;
  bottom: 5%;
  width: 5vw;
  min-width: 40px;
}