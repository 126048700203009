.welcomeAnimation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(90deg, #30B8FD , #1878E9);
}

.welcomeAnimation__hexagon {
  display: flex;
  height: 60vh;
  aspect-ratio: 0.875;
  justify-content: center;
  align-items: center;
}

.welcomeAnimation__hexagonContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.welcomeAnimation__names, .welcomeAnimation__subjectName, .welcomeAnimation__uploaderName {
  font-size: 8vw;
}

.welcomeAnimation__numbers {
  font-size: 20vw;
}

.welcomeAnimation__text {
  font-size: 10vw;
  color: white;
  font-weight: 800;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
}

.welcomeAnimation__questionMark {
  position: static;
}

.welcomeAnimation__skipAnimationButton {
  display: none;
}