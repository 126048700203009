.gameQuestion__backgroundGradient {
  background-image: linear-gradient(#f84c77, #F7A069);
  height: 100%;
  padding-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gameQuestion__textContainer {
  border-style: solid;
  border-color: white;
  border-width: 1.7vw;
  width: 70%;
  height: 60%;
  border-radius: 6vw;
  max-width: 1000px;
  max-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gameQuestion__text {
  font-size: 6vw;
  color: white;
  font-weight: 400;
  width: 90%;
  word-wrap: break-word;
}

.gameQuestion__clickableArea {
  width: 13%;
  height: 4vw;
  position: absolute;
  right: 0;
}

.gameQuestion__nextArrow {
  height: 100%;
  animation-name: gameQuestionArrow;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
}

@keyframes gameQuestionArrow {
  0%   {margin-right: 20%;}
  50%  {margin-right: -20%;}
  100% {margin-right: 20%;}
}




@media screen and (min-width: 1000px) {
  .gameQuestion__text {
    font-size: 60px;
  }
}