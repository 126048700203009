.downloadText {
  margin: 0;
  color: white;
  font-size: 15px;
  font-weight: 100;
}

.backgroundGradient {
  background-image: linear-gradient(#f84c77, #F7A069);
  height: 100%;
  padding-top: 0;
}

.centreFlexBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60%;
}

.progressContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40%;
}

.downloadContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.logoDiv {
  margin-top: 20%;
  width: 30%;
  max-width: 400px;
}

.spinner {
  animation: spinner-spin infinite 1.7s linear;
}

@keyframes spinner-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.progressBar {
  border-color: white;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  height: 10px;
  width: 30%;
  overflow: hidden;
  max-width: 400px;
}

.progressFill {
  background-color: white;
  height: 100%;
}

.downloadText {
  margin: 15px;
}

.button {
  border-color: white;
  border-radius: 25px;
  border-width: 1px;
  border-style: solid;
}

.buttonText {
  margin: 0;
  color: white;
  font-size: 20px;
  font-weight: 300;
  padding: 10px;
}

.startButtonContainer,
.retryContainer {
  width: 30%;
}
