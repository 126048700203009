.answer_page__video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.answer_page__skip_button {
  position: absolute;
  bottom: 5%;
  right: 5%;
}

.answer_page__skip_button_image {
  width: 44px;
  height: auto;
}

.answerPage {
  background-color: black;
}
