.finalScoreScreen__background {
  background-image: linear-gradient(#C17CBE, #4A90E2);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.finalScoreScreen__componentsContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1200px;
}

.componentsContainer__wahoo {
  width: 30%;
  background-color: #50E3C2;
  color: white;
  font-size: 4vw;
  font-weight: 700;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 5px;
}

.componentsContainer__mainScoreContainer {
  width: 50%;
  background-color: #FDFCEE;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.mainScoreContainer__starBadge {
  width: 30%;
}

.mainScoreContainer__subjectScoreContainer {
  width: 90%;
  display: flex;
  flex-direction: row;
  margin-top: 5%;
  margin-bottom: 8%;
}

.subjectScoreContainer__hexagon {
  width: 33%;
}

.finalScoreHexagon {
  width: 100%;
}

.subjectScoreContainer__nameScoreContainer {
  width: 67%;
  margin-left: 7%;
  text-align: left;
}

.nameScoreContainer__niceOneText {
  color: #4a4a4a;
  font-size: 2.5vw;
  font-weight: 700;
}

.nameScoreContainer__score {
  width: 70%;
  background-color: #EFE5E5;
  text-align: center;
  border-radius: 10px;
  font-size: 6vw;
  font-weight: 500;
  color: #4a4a4a;
  margin: 5% 10% 0 10%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 4%;
  padding-bottom: 7%;
}

.nameScoreContainer__scoreText {
  width: 60%;
  background-color: #B8E986;
  margin: 0 auto;
  text-align: center;
  margin-top: -5%;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.nameScoreContainer__scoreText>* {
  padding: 5%;
  margin: 0;
  font-size: 2vw;
  color: #4a4a4a;
}

.mainScoreContainer__iconsContainer {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.iconsContainer__icons {
  background-color: #16BBD8;
  margin: -5% 5px 0 5px;
  padding: 2% 4%;
  border-radius: 1vw;
  max-width: 90px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
}

.iconsContainer__icon {
  width: 4vw;
  max-width: 50px;
}

.confettiAnimation {
  bottom: 10%;
  right: 50%;
  position: absolute;
}

/* Bounce animation */

.finalScoreScreen__componentsContainer--animateIn {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
  -webkit-animation-duration: 1.25s;
  animation-duration: 1.25s;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes bounceIn {
  0%, 20%, 40%, 60%, 80%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(.9, .9, .9);
    transform: scale3d(.9, .9, .9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(.97, .97, .97);
    transform: scale3d(.97, .97, .97);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounceIn {
  0%, 20%, 40%, 60%, 80%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(.9, .9, .9);
    transform: scale3d(.9, .9, .9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(.97, .97, .97);
    transform: scale3d(.97, .97, .97);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.final_score_hexagon {
  filter: drop-shadow( 0 6px 4px rgba(0, 0, 0, 0.2));
}

@media screen and (min-width: 1000px) {
  .componentsContainer__wahoo {
    font-size: 40px;
  }
  .nameScoreContainer__score {
    font-size: 60px;
  }
  .nameScoreContainer__scoreText>* {
    font-size: 20px;
  }
  .nameScoreContainer__niceOneText {
    font-size: 30px;
  }
}
